import { plainToClass } from "class-transformer";
import { UserDirectoryEntity } from "./user-directory.objects";
import { TableColumnVisibilityController, TableColumnGroup } from "../../../shared/scripts/table-column-visibility";
import { Unique } from "../../../shared/scripts/array-functions";
import { IFilter, InitializeFilterDropdown } from "../../../shared/scripts/filtering";
//import { UserExperienceEntry, UserExperience, ExpertiseArea } from "../../../auth/pages/profile/scripts/expertise.objects";



export class UserDirectoryPage implements IFilter {

    users: UserDirectoryEntity[];
    dataTable: any;
    tableColumnController: TableColumnVisibilityController;
    filterDropdownIds: string[];
    visibleUserEmails: string;

    constructor(userJson: any) {
        $(() => {

            console.log(userJson);


            this.users = plainToClass(UserDirectoryEntity, <UserDirectoryEntity[]>userJson);

            this.users = this.users.map((user: UserDirectoryEntity) => {
                user.initialize();
                return user;
            });

            this.filterDropdownIds = ['#organization-dropdown'];
            this.initializeFilters();

            this.initializeTable();
        });
    }

    initializeFilters(): void {
        InitializeFilterDropdown('#organization-dropdown', Unique(this.users.map((user: UserDirectoryEntity) => user.Organization), true), this);
    }
    //    $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
    //        this.filterUserDirectory();
    //    });


    initializeTable(): void {
        this.dataTable = $('#user-directory-table').DataTable({
            "dom": '<"top-controls"<"search-bar"f><"count-found"B>>rtip',
            autoWidth: true,
            info: false,
            paging: false,
            search: true,
            scrollX: true,
            scrollY: '60vh',
            scrollCollapse: true,
            orderCellsTop: true,
            fixedColumns: {
                leftColumns: 2
            },
            columns: [
                { data: { _: "DisplayName", sort: "SortName" }, title: "Name", className: "text-left font-size12" },
                { data: { _: "DisplayEmail", sort: "Email" }, title: "Email", className: "text-left font-size12" },
                { data: { _: "ProjectRole", sort: "ProjectRole" }, title: "Role", className: "project text-left font-size12" },
                { data: { _: "DisplayInstitution", sort: "Organization" }, title: "Organization", className: "location text-left font-size12" },

            ],
            buttons: [
                {
                    extend: 'csv',
                    text: '<i class="fas fa-file-download"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    //exportOptions: {
                    //    columns: [1, 2, 3, 4]
                    //}
                },
                {
                    text: '<i class="fas fa-envelope"></i>',
                    titleAttr: 'Send Email',
                    action: (e, dt, node, config) => {
                        //console.log(this.visibleUserEmails);

                        navigator.clipboard.writeText(this.visibleUserEmails).then(
                            () => {
                                $('#directory-email-modal').modal('show');
                                $('#modal-email-list').text(this.visibleUserEmails);
                            });
                    }
                }
            ],
            data: this.users
        });

        $(".top-controls").addClass('row mx-0');

        $(".column-select").addClass('col-12 col-md-4 px-0');
        $(".column-select").empty().html('<div id="column-select"></div>');

        $(".search-bar").addClass('col-12 col-md-3 mr-auto');

        //$(".spacer").addClass('col-12 col-md-3'); 0
        $(".count-found").addClass('col-12 col-md-4 d-flex justify-content-end vertical-align-middle align-self-end my-2 pr-0');
        //<span id="people-found-count" class="font-italic font-size12" > </span>
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"><span id=\"people-found-count\"></span></div>');
        $(".count-found").prepend('<div class=\"table-message d-inline-block align-self-center mr-2\"></div>');

        $(".top-controls").prepend($("#organization-select"));
        /*this.tableColumnController = new TableColumnVisibilityController('user-directory-table', 'column-select', [
            new TableColumnGroup("Project Details", true, [], "project"),
            new TableColumnGroup("Institution", true, [], "location"),
        ]);*/

        $('#user-directory-table').on('search.dt', (e, settings) => {
            this.createEmailList(this.dataTable.rows({ search: 'applied' }).data());
            this.setPeopleFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });
        this.createEmailList(this.users);
        this.setPeopleFoundLabel(this.users.length);
    }

    updateTable(matchingUsers: UserDirectoryEntity[]): void {

        this.dataTable.clear();
        this.dataTable.rows.add(matchingUsers).draw();
        this.setPeopleFoundLabel(matchingUsers.length);
        this.createEmailList(matchingUsers);
    }

    triggerFilter(): void {

        let matchingUsers: UserDirectoryEntity[] = [];

        let userMatches: boolean;
        this.users.forEach((user: UserDirectoryEntity) => {

            userMatches = true; //must match all filters

            if (!user.matchesFilter(this.getSelectedDropdownValues('#organization-dropdown'))) {
                userMatches = false;
            }

            if (userMatches) { matchingUsers.push(user); }
        });

        this.updateTable(matchingUsers);
    }

    getSelectedDropdownValues(selectId: string): any {
        //return $(selectId).selectpicker('val');
        return $(selectId).val();
    }

    setPeopleFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#people-found-count').text("Nobody Found"); break;
            case 1: $('#people-found-count').text("1 Person Found"); break;
            default: $('#people-found-count').text(count + " People Found");
        }
    }

    createEmailList(matchingUsers: UserDirectoryEntity[]): void {
        this.visibleUserEmails = matchingUsers.map((user: UserDirectoryEntity) => user.Email).join("; ");
        //console.log(this.visibleUserEmails);
    }


    resetFilters(): void {

        ['#organization-dropdown'].forEach((dropdownId: string) => {
            $(dropdownId).selectpicker('selectAll');
            $(dropdownId).selectpicker('refresh');
        });

        this.dataTable.search('').draw();
        this.updateTable(this.users);
    }
}