export function InitializeFilterDropdown(selectId: string, values: string[], tableFilter: IFilter, compoundValues: any[] = undefined, selected: boolean = true): void {
    $(selectId).selectpicker({
        dropupAuto: false,
        selectedTextFormat: "count",

        countSelectedText: (numSelected, numTotal) => {
            if (numSelected == numTotal) {
                return "All";
            }
            else {
                return numSelected + " Selected";
            }
        }
    });

    if (compoundValues == undefined) {
        values.forEach((element: any) => {
            $(selectId).append(new Option(element, element, false, selected));
        });
    }
    else {
        //compoundValues.forEach((element: ExpertiseArea) => {
        //    $(selectId).append(new Option(element.Expertise, element.Id, false, selected));
        //});
    }

    $(selectId).selectpicker("refresh");

    $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        tableFilter.triggerFilter();
    });
}

export function GetSelectedDropdownValues(selectId: string): any {
    return $(selectId).val();
}

/*export function InitializeDateSelector(selectorId: string, tableFilter: IFilter): void {
    $('#' + selectorId).datepicker({
        todayBtn: "linked",
        clearBtn: true,
        autoclose: true,
        todayHighlight: true,
        orientation: "bottom auto"
    });

    $('#' + selectorId).datepicker().on("clearDate", () => {
        $('#' + selectorId).datepicker('update', '');
        tableFilter.triggerFilter();
    });

    $('#' + selectorId).datepicker().on("changeDate", () => {
        tableFilter.triggerFilter();
    });
}

export function GetSelectedDate(selectorId: string): any {
    return $(selectorId).datepicker('getDate');
}*/

export function ResetDropdownFilters(filterList: string[]): void {
    filterList.forEach((dropdownId: string) => {
        $(dropdownId).selectpicker('selectAll');
        $(dropdownId).selectpicker('refresh');
    });
}

/*export function ResetDateFilters(filterList: string[]): void {
    filterList.forEach((filterId: string) => {
        $(filterId).datepicker('update', '');
    });
}*/

export function SelectDropdownOption(selectId: string, values: string[]): void {
    $(selectId).selectpicker('val', values);
}

export interface IFilter {
    triggerFilter(): void;
}