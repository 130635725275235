//import { UserExperience } from "../../../auth/pages/profile/scripts/expertise.objects";
import { FormatStringByWordLength, DoArraysOverlap, Split, Join } from "../../../shared/scripts/array-functions";

export class UserDirectoryEntity {

    FirstName: string;
    LastName: string;
    ProjectRole: string;
    Email: string;
    Organization: string = "";

    DisplayName: string;
    SortName: string;
    DisplayEmail: string = "";
    DisplayInstitution: string = "";
    DisplayRole: string = "";
    HiddenFilter: string = "";

    constructor() { }

    initialize(): void {

        //console.log(this);

        this.DisplayName = this.FirstName + " " + this.LastName;
        this.DisplayName = FormatStringByWordLength(this.DisplayName, 4);
        this.SortName = this.LastName + " " + this.FirstName;

        if (this.Email?.length > 0) {
            this.DisplayEmail = "<a href='mailto:" + this.Email + "'><span class='nowrap'>" + this.Email.toLowerCase() + "<i class='fa fa-envelope link-icon' aria-hidden='true'></i></span></a>";
        }

        this.DisplayInstitution = FormatStringByWordLength(this.Organization, 4);
        this.DisplayRole = FormatStringByWordLength(this.ProjectRole, 4);
    }

    //matchesFilter(affiliation: any, institute: any, country: any, basicScienceExpertise: any, diagnosticExpertise: any, helminthExpertise: any, protozoanExpertise: any, bacteriaExpertise: any): boolean {
    matchesFilter(institute: any): boolean {

        if (!DoArraysOverlap(institute, this.Organization)) { return false; }

        return true;
    }
}